

import { Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import { AddnewPack } from "./addPackSlice";





const AddPack = ({ visible, setVisible }) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "", description: "", amount: 0, currency: "",
        price: 0, sale: false, saleRate: 0, status: "active", type: "", inAppProductID: ""
    });
    const isFetchingAddPack = useSelector((state) => state.addPack.isFetchingAddPack);
    const fetchAddPackResult = useSelector((state) => state.addPack.fetchAddPackResult);

    useEffect(() => {
        if (fetchAddPackResult) {
            setFormData({
                name: "", description: "", amount: 0, currency: "",
                price: 0, sale: false, saleRate: 0, status: "active", type: "",
                inAppProductID: ""
            })
            setVisible(false)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAddPackResult]);
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handelSelectType = (value) => {
        setFormData({ ...formData, currency: value, inAppProductID: "" });
    };

    const handleFormSubmit = (event) => {
        if (!fetchAddPackResult) {

            dispatch(AddnewPack(formData));
        }
    };


    return (
        < >
            <Modal title="Ajouter un pack"

                open={visible}
                okText="Confirmer"
                centered
                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{
                    disabled: (formData.name === "" || formData.amount < 1 || formData.type === "" || formData.description === ""),
                    loading: isFetchingAddPack
                }}
                onCancel={() => {
                    console.log('FormData ', formData);
                    setVisible(false)
                }}
                width={600}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}>
                <Row justify="center" style={{ marginTop: "40px" }}>


                    <Col span={24} >
                        <Form
                            // layout="vertical"
                            labelAlign="right"
                            labelCol={{ span: 5, offset: 0 }}
                            style={{ display: "block", }} >
                            <Form.Item
                                name="type"
                                label="Type"

                            >
                                <Radio.Group onChange={handleInputChange}
                                    optionType="button"
                                    buttonStyle="solid"
                                    value={formData.type}
                                    name="type"
                                    label="Type">
                                    <Radio value={"module"}>Module</Radio>
                                    <Radio value={"diamants"}>Diamants</Radio>
                                    <Radio value={"hearts"}>Cœurs</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="Nom"

                            >
                                <Input
                                    value={formData.name}
                                    name="name"
                                    type="name"
                                    placeholder="Nom du pack"
                                    style={{ width: "80%" }}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Input.TextArea
                                    value={formData.description}

                                    autoSize
                                    name="description"
                                    type="textarea"
                                    placeholder="Description"
                                    style={{ width: "80%" }}

                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Quantité"
                                name="amount"
                            >
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    value={formData.amount}

                                    // step={0.01}
                                    name="amount"
                                    placeholder="Quantité"
                                    style={{ width: "20%" }}

                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            amount: value,
                                        });
                                    }} />
                            </Form.Item>
                            <Form.Item
                                label="Monnaie"

                                name="currency"
                            >
                                <Select
                                    value={formData.currency}
                                    style={{ width: "50%" }}

                                    options={[
                                        {
                                            value: 'diamonds',
                                            label: 'Diamants',
                                        },
                                        {
                                            value: 'money',
                                            label: 'Argent',
                                        },

                                    ]}
                                    onSelect={handelSelectType}
                                />
                            </Form.Item>
                            <Form.Item
                                name="inAppP"
                                label="Stores ID"

                            >
                                <Input
                                    disabled={formData.currency !== "money"}
                                    value={formData.inAppProductID}
                                    name="inAppProductID"
                                    placeholder="ID sur play store / App store"
                                    style={{ width: "80%" }}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Coût"
                                name="price"
                            >
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    step={0.01}
                                    value={formData.price}

                                    name="price"
                                    style={{ width: "20%" }}

                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            price: value,
                                        });
                                    }} />
                            </Form.Item>
                            <Form.Item label="En Soldes :"
                            >
                                <Switch
                                    checked={formData.sale}
                                    onChange={(checked) => {
                                        setFormData({
                                            ...formData,
                                            sale: checked,
                                            saleRate: 0

                                        });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Réduction de :" >
                                <InputNumber
                                    disabled={!formData.sale}
                                    step={1}
                                    value={!formData.sale ? 0 : formData.saleRate * 100}
                                    formatter={(value) => `% ${value}`}
                                    parser={(value) => value.replace('%', '')}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            saleRate: (value / 100),
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Activation"
                            >
                                <Checkbox checked={formData.status === "active"} onChange={(e) => {
                                    setFormData({ ...formData, status: e.target.checked ? "active" : "inactive" })
                                }}>Activé à la création</Checkbox>
                            </Form.Item>
                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default AddPack;